:root {
	font-size:20px;
	--background: #fff;
	--color1: #000;
	--color2: #fc03f0; /*#0099cc; #037da6; #d71f85; */
	--color3: #ccc;
	--color4: #222022;
	--color5: #424242;
	--text: #0b2248;
	--text2: #fc03f0;
	
}

::-webkit-scrollbar{
	width: 4px;
	height: 4px;
}
::-webkit-scrollbar-thumb{
	background: var(--color2);
	border-radius: 0px;
	z-index:2
}
::-webkit-scrollbar-thumb:hover{
	background: #B3AFB3;
}
::-webkit-scrollbar-track{
	background: #F0F0F0;
	border-radius: 0px;
	box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

aside::-webkit-scrollbar-thumb {
	background: rgba(0,0,0,0)
}
.hidden {
	display:none!important;
}
.transparent {
	opacity: 0;
	pointer-events:none;
	cursor:default
}

.row {
	flex-direction:row;
	display:flex;
}

.column {
	display:flex;
	flex-direction:column;
}

.w20 {
	width:20%;
}
.w30 {
	width:30%;
}
.w40 {
	width:40%;
}
.w50 {
	width:50%;
}

html {
	width:100%;
	color: var(--text);
	height:100%;
}

body {
	font-family: 'Quicksand', sans-serif;
	background-color:var(--background);
	width:100%;
	height:100%;
	margin:0;
	padding:0;
	display:flex;
	flex-direction:column;
	overflow-x:hidden;
}

#root {
	height:100%;
}

.app {
	display:flex;
	flex-direction:column;
	height:100%;
}

a {
	color:var(--text);
	text-decoration:none;
}

header {
	width:100%;
	min-height:120px;
	text-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
header h1 {
	color:var(--text);
	font-size:2.5rem;
	margin:30px 0 0 0;
	font-weight:400;
	width:max-content
}
header h2 {
	margin: 0;
	font-size:1.2rem;
	font-weight:400;
	width:max-content;
	max-width:70%;
}

header h2:after {
	content:'';
	display:block;
	width:70%;
	margin:0 15%;
	height:2px;
	background-color:var(--color2);
}

header .admin__gear {
	position:fixed;
	top:25px;
	right:25px;
	margin: 0;
	border: 0;
	border-radius: 25px 0 0 25px;
	background-color: rgba(255, 255, 255, 0);
	padding: 0;
	width: 51px;
	height:51px;	
	vertical-align: middle;
	text-align: center;
	font-size: inherit;
	-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
	cursor:pointer;
	z-index:99;
}

header .admin__gear > svg {
		animation-name: rotate-out;
		animation-duration: 0.5s;
}

header .admin__gear:hover > svg, header .admin__gear:checked > svg {  
	animation-name: rotate-in;
	animation-duration: 0.5s;
}

header #admin {
	display:none;
}

aside {
	position:fixed;
	left:0;
	top:0;
	bottom:0;
	background-color:#eee;
	color:var(--text);
	transition:1s;
	z-index:6;
	box-sizing:border-box;
	padding:20px 15%;
	display:flex;
	flex-direction:column;
	width:100%;
	height:100%;
	overflow:scroll;
}
aside.active {
	left:100vw;
}

aside .row > p {
	flex:1;
	margin:0 5% 0 0;
}

aside .row > .column {
	flex:1;
	margin: 0 0 0 5%;
}

aside .row > .column p {
	margin: 0;
	width:100%;
}

aside .library {
	width:100%;
	display:inline-flex;
	height:40px;
	margin:6px auto;
}

aside .personal::before {
	content:'';
	width:100%;
	background:var(--color3);
	height:2px;
	margin: 20px 0;
}
aside .personal {
	width:100%;
	flex:1;
	position:relative;
}

aside .personal h3, aside .personal p {
	margin:0px;
}

aside .personal ul {
	list-style:none;
	padding:0;
}

aside .personal ul li {
	width:100%;
	display:flex;
	
}

aside .personal ul li i {
	font-style:normal;
	width:max-content;
	display:inline-flex;
	font-weight:900;
	margin:0px 10px 0px 0px;
}
aside .personal ul li input {
	border: 1px solid var(--color3);
	width:auto;
	margin:0px 10px 0px 0px;
	display:inline-flex;
	flex-grow:1;
	word-break:break-word;
}
aside .personal ul li button {
	width:100px;
	display:inline-flex;
	justify-content:center;
	align-items:center;
	font-size:x-large;
}

aside .personal .row {
	width:100%;
}

aside .personal .row input {
	flex: 1;
	height:40px;
	margin:10px 10px 10px 0;
	font-size:large;
	padding: 2px 0 0 0;
	box-sizing:border-box;
}
aside .personal .row input:not([type=submit]) {
	padding: 2px 0 0 10px;
}

aside .personal .overlay  {
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background: rgba(0, 0, 0, 0.7);
	color:var(--background);
	display:flex;
	justify-content:center;
	align-items:center;
	text-align:center

}

aside .cookieRedo {
	font-size: 18px;
}

aside .cookieRedo button {
	width:100%;
	height:40px;
	margin:10px 0;
	background : var(--background);
	border:1px solid rgb(118, 118, 118);
	color:rgb(133,133,133);
	cursor:pointer;
	font-size:large;
	text-transform: uppercase;
	padding-top:3px;
}
header #admin:checked + .admin__gear aside {
	left:0;
}


main {
	display:flex;
	width:90%;
	margin:0px auto;
}

.searchbox {
	margin:15px auto;
}

main input {
	height:3rem;
	width:30rem;
	margin:0 auto;
	border-radius:20px;
	border-width: 1px 1px 3px 2px;
	border-style: solid;
	border-image:   linear-gradient(15deg, var(--color1), #555) 5;
}

.app h4 {
	justify-self:center;
	align-self:center;
	width:max-content;
	position:absolute;
	word-break:break-word;
	width:100%;
	text-align:center
}
.sbx-custom {
  display: inline-block;
  position: relative;
  width: 630px;
  height: 49px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: x-large;
  margin-bottom: 20px;
}

.sbx-custom__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-custom__input {
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 66px;
  box-shadow: inset 0 0 0 1px var(--color3);
  background: #FFFFFF;
  padding: 0;
  padding-right: 41px;
  padding-left: 51px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-sizing: border-box;
}

.sbx-custom__input::-webkit-search-decoration, .sbx-custom__input::-webkit-search-cancel-button, .sbx-custom__input::-webkit-search-results-button, .sbx-custom__input::-webkit-search-results-decoration, .admin__pass::-webkit-search-decoration, .admin__pass::-webkit-search-cancel-button, .admin__pass::-webkit-search-results-button, .admin__pass::-webkit-search-results-decoration {
  display: none;
}

.sbx-custom__input:hover {
  box-shadow: inset 0 0 0 2px #b3b3b3;
}

.sbx-custom__input:focus, .sbx-custom__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--color2);
  background: #FFFFFF;
}

.sbx-custom__input::-webkit-input-placeholder {
  color: #BBBBBB;
}

.sbx-custom__input::-moz-placeholder {
  color: #BBBBBB;
}

.sbx-custom__input:-ms-input-placeholder {
  color: #BBBBBB;
}

.sbx-custom__input::placeholder {
  color: #BBBBBB;
}

.sbx-custom__button {
	position:absolute;
	top: 0;
	margin: 0;
	border: 0;
	border-radius: 25px 0 0 25px;
  	background-color: rgba(255, 255, 255, 0);
  	padding: 0;
  	aspect-ratio:1;
  	height: 100%;
  	border-radius: 0 25px 25px 0;
	background-color: rgba(255, 255, 255, 0);
	padding: 0;
	aspect-ratio:1;
	height: 100%;
	-webkit-user-select: none;
	   -moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
		
}

.sbx-custom__submit {
  right: inherit;
  left: 0;
  
  
}

.sbx-custom__reset {
	display: none;
	cursor: pointer;

	right: 0;
	fill: rgba(0, 0, 0, 0.5);
  }

.sbx-custom__submit:hover, .sbx-custom__submit:active {
  cursor: pointer;
}

.sbx-custom__submit:focus {
  outline: 0;
}

.sbx-custom__submit svg, .admin__gear svg {
  height:60%;
  aspect-ratio:1;
  vertical-align: middle;
  fill: var(--color2);
}


.sbx-custom__reset:focus {
  outline: 0;
}

.sbx-custom__reset svg {
  height:50%;
  aspect-ratio:1;
}

.sbx-custom__input:valid ~ .sbx-custom__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
          animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
          animation-duration: .15s;
}

body article {
	width:70%;
	padding:0 15%;
	display:flex;
	flex-direction:column;
	background-color:var(--background);
	z-index:2;
}


article h3, article h2 {
	color:var(--text);
	margin:0px 8px;
	font-size:1.5rem;
}
article h2 {
	cursor:pointer;
}
article h3 {
	font-size:1rem;
	font-weight:400;
}

article ul {
	list-style:none;
	padding:0;
	margin:0;
}
article ul li {
	display:flex;
	flex-direction:row;
	height:auto;
}

article ul li a {
	font-weight:bold;
	font-size:1em;
	height:20px;
	line-height:20px;
	transition:0.4s;
}
article ul li p {
	font-size:1em;
}

article ul li a:hover {
	color:var(--text2);
}

article ul li p, article ul li a {
	margin:8px;
	width:35%;
	height:auto;
	word-break: break-word;
}

article ul li p {
	color:var(--text);
	width:60%;
	margin-left:5%;
/*	display:none*/
}

article ul:empty {
	display:none;
}

body article:empty {
	display:none;
}

body article::after {
	content:'';
	display:block;
	width:100%;
	height:2px;
	background-color:var(--color2);
	margin:15px 0px;
}
body content {
	flex:1 0 auto;
}
body footer {
	width:100%;
	height:max-content;
	background-color:var(--color4);
	display:flex;
	flex-direction:row;
	color:var(--background);
	margin-top:50px;
}
body footer a {
	color:var(--background);
	cursor:pointer;
	font-size:0.8rem;
	transition:0.4s;
	margin:5px 0px;
}
body footer a:hover {
	color:var(--text2)
}
body footer .logo {
	height:250px;
	object-fit:contain;
}

body footer .column {
	justify-content:center;
	align-items:flex-start;
}

body footer .column h2, body footer .column h5 {
	margin:0px;
}

body footer .column h5 {
	background-color:var(--color2);
	padding:0px 5px;
	line-height:40px;
}

body footer .column h5 svg {
	margin-bottom:-5px;
}


@media only screen and (max-width:1200px) {
	:root {
		font-size:30px;
	}
	main {
		margin:0px;
	}
	.searchbox {
		width:100%;
	}
	.sbx-custom {
		width:100%;
		height:55px;
	}
	.sbx-custom__input {
		font-size: 1.2rem;
		padding-left:85px;
	}
	/*.sbx-custom__submit {
		width:90px;
	}*/
	/*.sbx-custom__submit svg, .admin__gear svg {
		width:55px;
		height:55px;
	}*/
	header .admin__gear {
		right:30px;
	}
	
	article ul li a {
		margin:24px 8px;
	}
	header h1 {
		font-size:2rem;
	}
	header h2 {
		font-size:1rem
	}
	article h2 {
		margin-top:-5px;
	}
}
@media only screen and (max-width:1000px) {
	main {
		margin: 10px 7.5%;
		width:85%;
	}

	body article {
		width:85%;
		padding: 0 7.5%;
	}
	aside {
		padding: 20px 7.5%;
	}
	article h2 {
		font-size:1.15rem
	}
	footer .logo {
		display:none;
	}
	body footer {
		padding:25px;
		box-sizing: border-box;
		flex:1;
	}
	body footer .column {
		flex-grow: 1;
	}
}

@media only screen and (max-width:800px) {
	body footer {
		display:flex;
		flex-direction:column;
	}
}
@media only screen and (max-width:600px) {
	:root {
		font-size:24px;
	}
	.sbx-custom__input {
		font-size: 0.9rem;
		padding-left:70px;
	}
	.sbx-custom__submit {
		width:70px;
	}
	.sbx-custom__submit::before {
		margin-right:0;
	}
	.sbx-custom__submit svg, .admin__gear svg {
		width:40px;
		height:40px;
	}
	header .admin__gear {
		right:25px;
	}
	
	article h3, article h2 {
		margin:8px 0;
	}

	article h2 {
		font-size:1.2rem;
	}
	article h3 {
		font-size:0.7rem;
	}
	article ul li {
		padding:15px 0;
		font-size:1rem;
	}
	article ul li p, article ul li a {
		width:48%;
		margin: 0;
	}
	article ul li p {
		margin: 0 0 0 auto;
	}
	aside p {
		font-size:0.9rem;
		letter-spacing:0px;
	}
	aside .personal ul li {
		flex-direction:column;
	}
	aside .personal ul li button{
		margin:8px 0px;
	}
	aside .row {
		flex-direction:column;
	}
	aside .personal .row input {
		height:60px;
		padding:10px;
	}
	aside .row > p, aside .row > .column {
		margin:0;
	}

	aside .row > p {
		margin-bottom:50px;
	}
	aside .cookieRedo {
		margin-top:25px;
		
	}
	aside .cookieRedo p {
		font-size: 0.75rem;
		font-weight:400;
	}
	

}

@media (max-width:400px) {
	header h1 {
		font-size:1.3rem
	}
	header .admin__gear {
		top:5px;
		right:5px;
	}
	header .admin__gear svg {
		width:32px;
		height:32px;
	}
}

/* COOKIE ALERT */
.cookie-alert-container::before {
	content:" ";
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
	background:#222022ef;
	position:fixed;
	z-index:-1;
}

/*.cookie-alert-container::after {
	content:" ";
	background:#aaa;
	width:100%;
	height:100%;
	position:absolute;
	z-index:-1;
	border-radius:30px;
}*/
.cookie-alert-container {
	z-index: 100;
	position:fixed;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;

	
	height:max-content;
	width:380px;
	top:0;
	bottom:0;
	left: 0;
	right: 0;

	margin: auto auto;	
	padding:30px;
	box-sizing:border-box;
}

.cookie-content {
	color:var(--background);
	display:flex;
	flex-direction:column;
	margin:0 15px;
	width:100%;
	flex:0;
	min-width:15%;
	height:250px;
	text-align:center
	
	
}
.cookie-content svg {
	fill:none;
	stroke-width:5;
	stroke:var(--background);
}

.cookie-content p {
	margin:0px;
	font-size:16px;
}

.cookie-button-wrapper {

	display:flex;
	flex-direction:column-reverse;
	width:300px;
	justify-content:flex-start;

}

.cookie-button.accept-button {
	height:50px;
	margin:15px;
	border:none;
	font-size:22px;
	font-weight:900;
	white-space:nowrap;
	font-family:Candara;
	cursor:pointer;
	background: var(--color2);
	color:#fff;
}

.cookie-button.decline-button {
	background:none;
	outline:none;
	border:none;
	font-size:18px;
	text-decoration: underline;
	color:#eee;
	font-weight:400;
	cursor:pointer;
}

@media (max-width: 1200px) {
	
}

@media (max-width: 880px) {
	.cookie-content {
		font-size:24px;
	}
}

@media (max-width: 580px) {
	.cookie-content {
		font-size:20px;
	}
	.cookie-button-wrapper {
		width:200px;
	}
}

@media (max-width: 400px) {
	.cookie-content {
		font-size:20px;
	}
	.cookie-button-wrapper {
		width:120px;
	}
}


@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
            transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
            transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

@keyframes rotate-in {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
}

@keyframes rotate-out {
	0% {
		transform: rotate(90deg);
	}
	100% {
		transform: rotate(180deg);
	}
}



.scroll-to-top {
	position: fixed;
	bottom: 25px;
	right: 25px;
	height: 38px;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: #fff;
	background-color: var(--background);
	cursor: pointer;
	color: #fc03f0;
	color: var(--color2);
	border: 1px solid;
	transition: .4s;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: large;
}
